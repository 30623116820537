<template>
  <div title="">
    <div class="title">用户管理</div>
    <div>
      <a-row style="margin-bottom: 2vh">
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.code" placeholder="登录账号" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="姓名" />
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button @click="$router.push('/setting/user/add')" type="primary"
              >新增</a-button
            >
          </div>
        </a-col>
      </a-row>

      <a-table
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column
          key="code"
          title="登录账号"
          data-index="code"
          align="center"
        ></a-table-column>
        <a-table-column
          key="name"
          title="姓名"
          data-index="name"
          align="center"
        ></a-table-column>
        <a-table-column key="sex" title="性别" data-index="sex" align="center">
          <template slot-scope="sex">
            <DataDictFinder dictType="gender" :dictValue="sex" />
          </template>
        </a-table-column>
        <a-table-column
          key="mobile"
          title="手机号"
          data-index="mobile"
          align="center"
        ></a-table-column>

        <a-table-column key="role" title="角色" align="center">
          <template slot-scope="text">
            <div>
              {{
                Array.isArray(text.roles)
                  ? text.roles.map((item) => item.name).join(",")
                  : "--"
              }}
            </div>
          </template>
        </a-table-column>

        <a-table-column title="状态" data-index="status" align="center">
          <template slot-scope="status">
            <DataDictFinder dictType="status" :dictValue="String(status)" />
          </template>
        </a-table-column>

        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="
                  $router.push('/setting/user/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a href="#" @click.prevent="deleteText(text)" class="danger"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import { fetchList, remove } from "@/api/setting/user";

export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      visible: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确定要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
  },
};
</script>
