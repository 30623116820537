import request from '../request'

export function fetchList(params) {
    return request({
        url: '/basic-service/user/list',
        params
    })
}

export function add(data) {
    return request({
        url: '/basic-service/user/add',
        method: 'POST',
        data,
    })
}

export function fetchDetail(params) {
    return request({
        url: '/basic-service/user/query',
        params
    })
}

export function edit(data) {
    return request({
        url: '/basic-service/user/update',
        method: 'POST',
        data,
    })
}

export function editBaseInfo(data) {
    return request({
        url: '/basic-service/user/updateBase',
        method: 'POST',
        data,
    })
}

export function remove(data) {
    return request({
        url: '/basic-service/user/delete',
        method: 'POST',
        data
    })
}

// 获取用户数据权限
export function fetchDataPermission(params) {
    return request({
        url: '/common-service/userDataPermission/query',
        params
    })
}

// 配置用户权限
export function configDataPermission(data) {
    return request({
        url: '/common-service/userDataPermission/config',
        method: 'POST',
        data
    })
}

// 查询某一个用户的详细信息，包括跟员工相关的
export function fetchUserEmployeeDetail(params) {
    return request({
        url: `/user-service/employee/query/${params.userId}`,
    })
}